module.exports = {
  background: '#747474',
  backgroundDark: '#272727',
  backgroundLight: '#9B9B9B',

  primary: '#14A76C',
  primaryLight: '#46db9d',
  primaryDark: '#2d8d67',

  secondary: '#FFE400',
  secondaryLight: '#fff675',
  secondaryDark: '#e6d42e',

  terciary: '#FF652F',
  terciaryLight: '#ffaf8c',
  terciaryDark: '#e67e58',
};
